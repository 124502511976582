<template>
  <div class="title-bar">
    <h1>{{ title }}</h1>
    <div class="title-bar__buttons">
      <a v-if="secondaryBtn" @click="secondaryPressed" class="secondary-btn">{{ secondaryBtnTitle }}</a>
      <!-- Export button -->
      <a v-if="exportBtn" @click="exportPressed" class="export-btn">Export</a>
      <!-- Submit buton -->
      <a v-if="submitBtn" @click="submitPressed" class="submit-btn">{{ submitBtnTitle }}</a>
      <!-- Edit buton -->
      <a v-if="edit" @click="editPressed" class="submit-btn edit">Edit</a>
      <!-- Add button -->
      <router-link v-if="addBtn" :to="addUrl" class="add-btn">
        <img src="@/assets/images/icons/plus.svg" alt="plus" />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TitleBar',
  props: {
    addBtn: Boolean,
    submitBtn: Boolean,
    exportBtn: Boolean,
    edit: Boolean,
    secondaryBtn: Boolean,
    title: String,
    addUrl: String,
    submitBtnTitle: String,
    secondaryBtnTitle: String
  },
  components: {},
  methods: {
    exportPressed() {
      this.$emit('exportPressed')
    },
    submitPressed() {
      this.$emit('submitPressed')
    },
    editPressed() {
      this.$emit('editPressed')
    },
    secondaryPressed() {
      this.$emit('secondaryPressed')
    },
  },
}
</script>
