var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"customerForm",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"row",attrs:{"label":"First name","label-for":"h-first-name","label-cols-md":"2","content-cols-lg":"4"}},[_c('validation-provider',{attrs:{"rules":"","name":"First name","vid":"user.givenName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-first-name","state":errors.length > 0 ? false : null},model:{value:(_vm.user.givenName),callback:function ($$v) {_vm.$set(_vm.user, "givenName", $$v)},expression:"user.givenName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"row",attrs:{"label":"Last name","label-for":"h-last-name","label-cols-md":"2","content-cols-lg":"4"}},[_c('validation-provider',{attrs:{"rules":"","name":"Last name","vid":"user.familyName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-last-name","state":errors.length > 0 ? false : null},model:{value:(_vm.user.familyName),callback:function ($$v) {_vm.$set(_vm.user, "familyName", $$v)},expression:"user.familyName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"row",attrs:{"label":"City","label-for":"h-city","label-cols-md":"2","content-cols-lg":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"City","vid":"user.address.city"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-city","state":errors.length > 0 ? false : null},model:{value:(_vm.user.address.city),callback:function ($$v) {_vm.$set(_vm.user.address, "city", $$v)},expression:"user.address.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"row",attrs:{"label":"Postcode","label-for":"h-postcode","label-cols-md":"2","content-cols-lg":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Postcode","vid":"user.address.postalCode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-postcode","state":errors.length > 0 ? false : null},model:{value:(_vm.user.address.postalCode),callback:function ($$v) {_vm.$set(_vm.user.address, "postalCode", $$v)},expression:"user.address.postalCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"row",attrs:{"label":"Street","label-for":"h-street","label-cols-md":"2","content-cols-lg":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Street","vid":"user.address.streetAddress"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-street","state":errors.length > 0 ? false : null},model:{value:(_vm.user.address.streetAddress),callback:function ($$v) {_vm.$set(_vm.user.address, "streetAddress", $$v)},expression:"user.address.streetAddress"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"row",attrs:{"label":"Street nummber","label-for":"h-street-number","label-cols-md":"2","content-cols-lg":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Street number","vid":"user.address.streetNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-street-number","state":errors.length > 0 ? false : null},model:{value:(_vm.user.address.streetNumber),callback:function ($$v) {_vm.$set(_vm.user.address, "streetNumber", $$v)},expression:"user.address.streetNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"row",attrs:{"label":"Entrance","label-for":"h-entrance","label-cols-md":"2","content-cols-lg":"4"}},[_c('b-form-input',{attrs:{"id":"h-entrance"},model:{value:(_vm.user.address.gate),callback:function ($$v) {_vm.$set(_vm.user.address, "gate", $$v)},expression:"user.address.gate"}})],1),_c('b-form-group',{staticClass:"row",attrs:{"label":"Flat number","label-for":"h-flat-number","label-cols-md":"2","content-cols-lg":"4"}},[_c('b-form-input',{attrs:{"id":"h-flat-number"},model:{value:(_vm.user.address.flatNumber),callback:function ($$v) {_vm.$set(_vm.user.address, "flatNumber", $$v)},expression:"user.address.flatNumber"}})],1),_c('b-form-group',{staticClass:"row",attrs:{"label":"Floor number","label-for":"h-floor","label-cols-md":"2","content-cols-lg":"4"}},[_c('b-form-input',{attrs:{"id":"h-floor"},model:{value:(_vm.user.address.floorNumber),callback:function ($$v) {_vm.$set(_vm.user.address, "floorNumber", $$v)},expression:"user.address.floorNumber"}})],1),_c('b-form-group',{staticClass:"row",attrs:{"label":"Phone","label-for":"h-phone","label-cols-md":"2","content-cols-lg":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Phone","vid":"user.telephone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-phone","state":errors.length > 0 ? false : null},model:{value:(_vm.user.telephone),callback:function ($$v) {_vm.$set(_vm.user, "telephone", $$v)},expression:"user.telephone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"row",attrs:{"label":"Email","label-for":"h-email","label-cols-md":"2","content-cols-lg":"4"}},[_c('validation-provider',{attrs:{"rules":"email","name":"Email","vid":"user.email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-email","type":"email","state":errors.length > 0 ? false : null},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"row",attrs:{"label":"Comment","label-for":"h-comment","label-cols-md":"2","content-cols-lg":"4"}},[_c('b-form-input',{attrs:{"id":"h-comment","name":"Comment"},model:{value:(_vm.user.description),callback:function ($$v) {_vm.$set(_vm.user, "description", $$v)},expression:"user.description"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }