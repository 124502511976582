<template>
  <validation-observer ref="customerForm" tag="form" @submit.prevent="onSubmit">
    <b-form>
      <b-row>
        <b-col cols="12">
          <b-form-group
            class="row"
            label="First name"
            label-for="h-first-name"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <validation-provider
              #default="{ errors }"
              rules=""
              name="First name"
              vid="user.givenName"
            >
              <b-form-input
                v-model="user.givenName"
                id="h-first-name"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            class="row"
            label="Last name"
            label-for="h-last-name"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <validation-provider
              #default="{ errors }"
              rules=""
              name="Last name"
              vid="user.familyName"
            >
              <b-form-input
                v-model="user.familyName"
                id="h-last-name"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            class="row"
            label="City"
            label-for="h-city"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="City"
              vid="user.address.city"
            >
              <b-form-input
                id="h-city"
                v-model="user.address.city"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            class="row"
            label="Postcode"
            label-for="h-postcode"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Postcode"
              vid="user.address.postalCode"
            >
              <b-form-input
                id="h-postcode"
                v-model="user.address.postalCode"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            class="row"
            label="Street"
            label-for="h-street"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Street"
              vid="user.address.streetAddress"
            >
              <b-form-input
                id="h-street"
                v-model="user.address.streetAddress"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            class="row"
            label="Street nummber"
            label-for="h-street-number"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Street number"
              vid="user.address.streetNumber"
            >
              <b-form-input
                id="h-street-number"
                v-model="user.address.streetNumber"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            class="row"
            label="Entrance"
            label-for="h-entrance"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <b-form-input id="h-entrance" v-model="user.address.gate" />
          </b-form-group>

          <b-form-group
            class="row"
            label="Flat number"
            label-for="h-flat-number"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <!--
              <validation-provider
                #default="{ errors }"
                rules="required|numeric"
                name="Flat number"
                vid="user.address.flatNumber"
              > -->
            <b-form-input
              id="h-flat-number"
              v-model="user.address.flatNumber"
            />
            <!--
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              -->
          </b-form-group>

          <b-form-group
            class="row"
            label="Floor number"
            label-for="h-floor"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <b-form-input id="h-floor" v-model="user.address.floorNumber" />
          </b-form-group>

          <b-form-group
            class="row"
            label="Phone"
            label-for="h-phone"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Phone"
              vid="user.telephone"
            >
              <b-form-input
                id="h-phone"
                v-model="user.telephone"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            class="row"
            label="Email"
            label-for="h-email"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="email"
              name="Email"
              vid="user.email"
            >
              <b-form-input
                id="h-email"
                type="email"
                v-model="user.email"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            class="row"
            label="Comment"
            label-for="h-comment"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <b-form-input
              id="h-comment"
              name="Comment"
              v-model="user.description"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormInput, BForm } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Toast from '@/components/Toast.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    userObject: { Object, required: true },
    action: { type: String, default: null },
  },
  data() {
    return {
      user: this.userObject,
    }
  },
  watch: {
    action(newVal) {
      if (newVal === 'add') {
        this.onSubmit()
      }
      if (newVal === 'update') {
        this.onSubmit()
      }
    },
  },
  methods: {
    async onSubmit() {
      const isValid = await this.$refs.customerForm.validate()
      if (isValid) {
        if (this.user.email === '') {
          delete this.user.email
        }

        if (this.action === 'add') {
          this.$Users
            .createResource({ body: this.user })
            .then(response => {
              if (response.status === 201) {
                this.$helper.showToast(
                  this.$toast,
                  Toast,
                  'New customer created',
                  '',
                  'success',
                )
                this.$router.push(`/customers`)
              }
            })
            .catch(error => {
              if (error.response) {
                this.$emit('clearAction')
                this.$helper.showToast(
                  this.$toast,
                  Toast,
                  error.response.data['hydra:title'],
                  error.response.data['hydra:description'],
                  'danger',
                )
              }
            })
        }

        if (this.action === 'update') {
          this.$Users
            .updateResource({ id: this.user.id, body: this.user })
            .then(response => {
              if (response.status === 200) {
                this.$helper.showToast(
                  this.$toast,
                  Toast,
                  'Customer updated',
                  '',
                  'success',
                )
                this.$router.push(`/customers`)
              }
            })
            .catch(error => {
              if (error.response) {
                this.$emit('clearAction')
                this.$helper.showToast(
                  this.$toast,
                  Toast,
                  error.response.data['hydra:title'],
                  error.response.data['hydra:description'],
                  'danger',
                )
              }
            })
        }
      } else {
        this.$emit('clearAction')
      }
    },
  },
}
</script>
